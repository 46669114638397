module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Healice","short_name":"Healice","description":"The premiere place for crystal healing and intuitive development. Here, you'll find the most comprehensive resources for personal and spiritual development.","lang":"en","start_url":"/","display":"standalone","icon":"src/assets/favicon/logo-bg.png","background_color":"#211229","theme_color":"#211229","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89ceb5e5dda2d52495e5c1a5ae68e147"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
