import React from 'react'

import { AnimatePresence } from 'framer-motion'

export const wrapPageElement = ({ element }) => (
    <AnimatePresence mode="wait" onExitComplete={() => window.scrollTo(0, 0)}>
        {element}
    </AnimatePresence>
)

export const onInitialClientRender = () => {
    setTimeout(() => {
        // @ts-ignore
        document.getElementById('___loader').style.display = 'none'
        // @ts-ignore
        document.getElementById('___gatsby').style.display = 'block'
    }, 2150)
}
